<template>
  <div class="card-buttons">
    <div class="card-buttons__settings">
      <hcc-button
        v-if="isOwner"
        @click="openChannelsSettings"
        color="primary"
        class="card-buttons__settings-button"
      >
        <span v-if="isMobile"> {{ $t("campaigns.cards.channels") }}</span>
        <span v-else> {{ channelSettings }}</span>
      </hcc-button>
      <hcc-button
        color="secondary"
        @click="openCampaignSettings"
        class="card-buttons__settings-button"
      >
        <span v-if="isMobile"> {{ $t("app.campaigns") }}</span>
        <span v-else> {{ campaignSettings }}</span>
      </hcc-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HccButton from '@/components/shared/HccButton/index.vue';

export default {
  components: {
    HccButton,
  },
  props: {
    showDuplicateButton: {
      type: Boolean,
      default: false,
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({ isOwner: 'shared/isOwner' }),
    campaignSettings() {
      return this.$t('campaigns.cards.campaign-settings');
    },
    channelSettings() {
      return this.$t('campaigns.cards.channels-settings');
    },
    campaignDuplicate() {
      return this.$t('campaigns.cards.duplicate');
    },
    campaignDelete() {
      return this.$t('campaigns.cards.delete');
    },
  },
  methods: {
    openCampaignSettings() {
      this.$emit('campaign-settings');
    },
    openChannelsSettings() {
      this.$emit('channels-settings');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/components/_campaign-card.scss";
</style>
