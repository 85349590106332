<template>
  <img :src="icon" alt=""/>
</template>

<script>
import messenger from '@/assets/images/channels/messenger.svg';
import sms from '@/assets/images/channels/sms.svg';
import telegram from '@/assets/images/channels/telegram.svg';
import webchat from '@/assets/images/channels/webchat.svg';
import whatsapp from '@/assets/images/channels/whatsapp.svg';

export default {
  props: {
    type: {
      type: String,
      require: true,
    },
  },
  computed: {
    icon() {
      switch (this.type) {
        case 'messenger':
          return messenger;
        case 'sms':
          return sms;
        case 'telegram':
          return telegram;
        case 'webchat':
          return webchat;
        case 'whatsapp':
          return whatsapp;
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped lang="scss">
  img {
    width: 20px;
    height: 20px;
  }
</style>
