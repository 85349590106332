<template>
  <content-loader
    :width="180"
    :height="160"
    :speed="2"
    primaryColor="#c3c3c3"
    secondaryColor="#ecebeb"
    :animate="true">
    <rect height="16" width="180" y="8" x="0" rx="3" ry="3"/>
    <rect height="10" width="90" y="45" x="0" rx="3" ry="3"/>
    <rect height="10" width="120" y="74" x="0" rx="3" ry="3"/>
    <rect height="10" width="75" y="103" x="0" rx="3" ry="3"/>
    <rect height="10" width="60" y="133" x="0" rx="3" ry="3"/>
  </content-loader>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

export default {
  components: {
    ContentLoader,
  },
};
</script>
