import { render, staticRenderFns } from "./Campaigns.vue?vue&type=template&id=27b07916&scoped=true&"
import script from "./Campaigns.vue?vue&type=script&lang=js&"
export * from "./Campaigns.vue?vue&type=script&lang=js&"
import style0 from "./Campaigns.vue?vue&type=style&index=0&id=27b07916&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27b07916",
  null
  
)

export default component.exports