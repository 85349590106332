<template>
  <div class="card">
    <div class="loading" v-if="isLoading" />
    <div
      class="status"
      :class="{
        'status-editable': showStatusButton,
        'status-disabled': !isActive,
      }"
      v-on="showStatusButton ? { click: changeStatus } : {}"
      v-tooltip="{ content: deactivate, visible: showStatusButton }"
    ></div>
    <div class="grid-x">
      <div v-if="isBlank" class="loader-wrapper">
        <card-loader />
      </div>
      <template v-else>
        <div class="panel-container">
          <card-left-panel
            class="left-panel"
            :campaign="campaign"
            :isOwner="isOwner"
            @updated="updateCampaign"
          />
          <card-right-panel
            class="right-panel"
            :createdAt="createdAt"
            :isOwner="isOwner"
            :isSuper="isSuper"
            :showDuplicateButton="showDuplicateButton"
            :showDeleteButton="canDelete"
            @edit="openEdit"
            @campaign-duplicate="openConfirmDuplicate"
            @delete-campaign="openConfirmDelete"
          />
        </div>
        <card-buttons
          class="card__buttons"
          @campaign-settings="openCampaignSettings"
          @channels-settings="openChannelsSettings"
        />
      </template>
    </div>
    <hcc-confirmation />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import CardLoader from '@/components/loaders/CampaignCard.vue';
import HccConfirmation from '@/components/shared/HccConfirmation/index.vue';
import CardLeftPanel from './CardLeftPanel.vue';
import CardRightPanel from './CardRightPanel.vue';
import CardButtons from './CardButtons.vue';

export default {
  components: {
    CardLoader,
    HccConfirmation,
    CardLeftPanel,
    CardRightPanel,
    CardButtons,
  },
  props: {
    campaign: {
      required: true,
    },
    isOwner: {
      required: true,
      type: Boolean,
    },
    showDuplicateButton: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      isSuper: 'shared/isSuper',
      campaigns: 'setting/getCampaigns',
      companies: 'shared/getFilterCompanies',
    }),
    ...mapState({
      selectedStore: state => state.shop.item,
      currentProducts: state => state.product.items,
    }),
    isBlank() {
      return !!this.campaign.isBlank;
    },
    isLoading() {
      return !!this.campaign.loading;
    },
    isActive() {
      return !!this.campaign.active;
    },
    showStatusButton() {
      return !this.isOwner && this.isActive;
    },
    createdAt() {
      if (this.campaign.createdAt) {
        const createdAt = this.$moment(this.campaign.createdAt);
        return createdAt.format('DD/MM/YYYY');
      }

      return 'N/A';
    },
    deactivateCampaign() {
      return this.$t('campaigns.cards.deactivate-campaign');
    },
    deactivate() {
      return this.$t('campaigns.cards.deactivate');
    },
    validateAllDataStore() {
      return this.selectedStore.descripcion
        && this.selectedStore.categoria
        && this.selectedStore.direccion;
    },
    canDelete() {
      return !this.campaign.active;
    },
  },
  methods: {
    ...mapActions({
      store: 'shop/getById',
      products: 'product/getItems',
      selectCampaign: 'setting/selectCampaign',
    }),
    changeStatus() {
      const data = {
        id: this.campaign.id,
        active: false,
      };

      this.$modal.show('confirmation', {
        title: this.deactivateCampaign,
        description: this.$t('campaigns.cards.activate-confirmation'),
        variant: 'default',
        confirm: () => this.$emit('updated', data),
      });
    },
    updateCampaign(campaign) {
      this.$emit('updated', campaign);
    },
    openEdit(action) {
      if (action === 'blocked-client') {
        this.selectCampaign(this.campaign.id);
        this.$router.push(`/campaigns/${this.campaign.id}/blocked-clients`);
      } else if (action === 'show-modal') {
        this.$emit('edit');
      }
    },
    openCampaignSettings() {
      this.$emit('campaign-settings', this.campaign.id);
    },
    openChannelsSettings() {
      this.$emit('channels-settings', this.campaign.id);
    },
    async openConfirmDuplicate() {
      const valid = await this.canDuplicate();
      if (!valid) {
        this.$toasted.global.error('La campaña no esta completa, favor de revisar la configuración de la campaña o de la tienda');
        return;
      }
      const company = this.findCompany(this.campaign);
      const maxAllowed = Math.floor(company ? company.maxUsers / 2 : 0);
      if (maxAllowed <= this.campaigns.length) {
        this.$toasted.global.error(`Solo puedes agregar ${maxAllowed} campañas/tiendas, Si quiere un ajuste, comunicate al departamento de ventas.`);
        return;
      }

      this.$modal.show('confirmation', {
        title: this.$t('campaigns.cards.duplicate-campaign'),
        description: this.$t('campaigns.cards.duplicate-confirmation'),
        variant: 'default',
        confirm: () => this.$emit('duplicate-campaign', this.campaign),
      });
    },
    openConfirmDelete() {
      this.$modal.show('confirmation', {
        title: this.$t('campaigns.cards.delete-campaign'),
        description: this.$t('campaigns.cards.delete-confirmation'),
        variant: 'default',
        confirm: () => this.$emit('delete-campaign', this.campaign),
      });
    },
    async canDuplicate() {
      const {
        id,
        schedules,
        categories,
        channels,
        admins,
        agents,
      } = this.campaign;
      await this.store(id);
      await this.products(id);

      return schedules.length > 0
        && categories.length > 0
        && channels.length > 0
        && this.validateAllDataStore
        && this.currentProducts.length > 0
        && admins > 0
        && agents > 0;
    },
    findCompany({ company }) {
      return this.companies.find(({ id }) => id === company);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_campaign-card.scss";
</style>
