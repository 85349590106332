<template>
  <div>
    <section class="main-content">
      <div v-show="isOwner" class="grid-x align-right margin-bottom--20">
        <div class="cell shrink">
          <button-filter
            v-bind:display="enabledFilters"
            v-on:change="onFiltersChanged"
          />
        </div>
      </div>
      <filters v-show="isOwner" :display="enabledFilters" />
      <div class="grid-x grid-margin-x grid-margin-y loader-container">
        <overlay-loader :loading="isLoading" />
        <div
          v-for="campaign in campaigns"
          :key="campaign.id"
          class="cell medium-6"
        >
          <campaign-card
            @edit="showModal(campaign, false)"
            @updated="updateCampaign"
            @campaign-settings="openCampaignSettings"
            @channels-settings="openChannelsSettings"
            @duplicate-campaign="confirmCampaignDuplicate"
            @delete-campaign="confirmDeleteCampaign"
            :is-owner="isOwner"
            :campaign="campaign"
            :showDuplicateButton="canCreate"
          />
        </div>

        <div class="cell medium-6" v-if="canCreate">
          <campaign-card-create
            :is-owner="isOwner"
            @create="showModal(null, true)"
            @saved="createCampaign"
          />
        </div>
      </div>
    </section>
    <campaign-modal
      v-if="canCreate"
      @edited="updateCampaign"
      @saved="createCampaign"
      :newCampaign="newCampaign"
      :isOwner="isOwner"
      :limitCampaigns="limitCampaigns"
      :role="role"
    />
    <hcc-confirmation />
  </div>
</template>

<script>
import {
  mapMutations, mapGetters, mapActions, mapState,
} from 'vuex';
import CampaignCard from '@/components/CampaignCard/index.vue';
import CampaignModal from '@/components/CampaignCard/CampaignModal.vue';
import Filters from '@/components/Filters.vue';
import errorHandler from '@/utils/error.handler';
import HccConfirmation from '@/components/shared/HccConfirmation/index.vue';
import duplicateCampaignMutation from '@/graphql/mutations/duplicateCampaign.gql';
import addCampaignMutation from '@/graphql/mutations/campaign/addCampaign.gql';
import deleteCampaignMutation from '@/graphql/mutations/campaign/deleteCampaign.gql';
import editCampaignMutation from '@/graphql/mutations/editCampaign.gql';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';

export default {
  components: {
    CampaignCard,
    CampaignModal,
    HccConfirmation,
    OverlayLoader,
    Filters,
    ButtonFilter: () => import('@/components/ButtonFilter.vue'),
    CampaignCardCreate: () => import('@/components/CampaignCardCreate.vue'),
  },
  data() {
    return {
      filters: null,
      enabledFilters: ['company'],
      newCampaign: null,
      isLoading: false,
      selectedCompany: null,
      limitCampaigns: 0,
    };
  },
  computed: {
    ...mapGetters({
      isOwner: 'shared/isOwner',
      isSuper: 'shared/isSuper',
      campaigns: 'setting/getCampaigns',
      role: 'shared/getUserRole',
      companies: 'shared/getFilterCompanies',
    }),
    ...mapState({
      user: state => state.shared.user,
      selectedStore: state => state.shop.item,
      currentProducts: state => state.product.items,
    }),
    canCreate() {
      return (this.isOwner || this.isSuper);
    },
    currentCompanyId() {
      return this.isOwner ? this.filterCompany : this.userCompany;
    },
    userCompany() {
      return this.user.company;
    },
    filterCompany() {
      return this.filters ? this.filters.companyId : 0;
    },
  },
  watch: {
    filters(newValue, oldValue) {
      if (newValue !== null && (oldValue === null || newValue.companyId !== oldValue.companyId)) {
        this.loadCampaigns();
        this.isLoading = true;
      }
    },
  },
  mounted() {
    if (this.filters !== null || !this.isOwner) {
      this.loadCampaigns();
    }
  },
  methods: {
    ...mapMutations({
      clearCurrentCampaign: 'setting/clearCurrentCampaign',
    }),
    ...mapActions({
      fetchCompanies: 'shared/fetchCompanies',
      fetchCampaigns: 'setting/fetchCampaigns',
      selectCampaign: 'setting/selectCampaign',
      updateCampaigns: 'setting/updateCampaign',
      addCampaign: 'setting/addCampaign',
      createStore: 'shop/save',
      deleteStore: 'shop/remove',
      clearDatabank: 'databank/clearDatabank',
    }),
    onFiltersChanged(filters) {
      if (filters.company) {
        this.selectedCompany = filters.company.name;
      }
      this.filters = filters;
    },
    async loadCampaigns() {
      this.isLoading = true;
      await this.fetchCampaigns(this.currentCompanyId);
      this.isLoading = false;
    },
    saveStore({
      id,
      name,
      company,
      messages = [],
      schedules = [],
    }, prevId = null) {
      let owner = {};
      if (this.user.role === 'super') {
        owner = {
          owner: {
            uid: this.user.id,
            nombre: this.user.name,
          },
        };
      }
      return this.createStore({
        id,
        prevId,
        nombre: name,
        nombreLegal: name,
        heydata: {
          company,
          campaign: id,
        },
        schedules,
        messages,
        ...owner,
      });
    },
    async createCampaign(data) {
      const result = await this.$apollo.mutate({
        mutation: addCampaignMutation,
        variables: {
          ...data,
          company: this.currentCompanyId,
          categories: [
            { title: 'Venta' },
            { title: 'No Venta' },
          ],
        },
      });

      this.addCampaign(result.data.addCampaign);
      await this.saveStore(result.data.addCampaign);
      this.fetchCompanies();
    },
    async updateCampaign(data) {
      try {
        this.updateLoadingCard(data.id, true);
        const result = await this.$apollo.mutate({
          mutation: editCampaignMutation,
          variables: data,
        });

        this.$toasted.global.success(this.$t('campaigns.updated'));
        this.updateCampaigns(result.data.editCampaign);
        await this.saveStore(result.data.editCampaign, result.data.editCampaign.id);
        this.updateLoadingCard(data.id, false);
        this.fetchCompanies();
      } catch (error) {
        this.$toasted.global.error(this.$t('common.error-saving'));
        errorHandler.logErrors(error);
      }
    },
    updateLoadingCard(id, status) {
      const index = this.getCampaignIndex(id);

      if (index > -1) {
        this.campaigns[index].loading = status;
      }
    },
    async confirmCampaignDuplicate(campaign) {
      this.isLoading = true;
      const { data: newCampaign } = await this.createNewCampaign(campaign);
      await this.saveStore(newCampaign.duplicateCampaign, campaign.id);
      await this.loadCampaigns();
    },
    async confirmDeleteCampaign(campaign) {
      this.isLoading = true;
      await this.deleteCampaign(campaign);
      await this.deleteStore(campaign.id);
      await this.loadCampaigns();
    },
    getCampaignIndex(id) {
      return this.campaigns.findIndex(c => c.id === id);
    },
    setLimitCampaigns() {
      const company = this.companies.find(({ id }) => id === this.filterCompany);
      const maxAllowed = Math.floor(company ? company.maxUsers / 2 : 0);
      this.limitCampaigns = maxAllowed - this.campaigns.length;
    },
    showModal(campaign, isNew) {
      this.newCampaign = isNew;
      this.setLimitCampaigns();
      this.$modal.show('campaign-modal', campaign);
    },
    openCampaignSettings(id) {
      this.clearDatabank();
      this.selectCampaign(id);
      this.$router.push(`/campaigns/${id}/settings`);
    },
    openChannelsSettings(id) {
      this.selectCampaign(id);
      this.$router.push(`/campaigns/${id}/channels-settings`);
    },
    async createNewCampaign(campaign) {
      return this.$apollo.mutate({
        mutation: duplicateCampaignMutation,
        variables: { id: campaign.id },
      });
    },
    deleteCampaign(campaign) {
      return this.$apollo.mutate({
        mutation: deleteCampaignMutation,
        variables: { id: campaign.id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~vue-loading-overlay/dist/vue-loading.css";
@import "~styles/views/_campaigns.scss";
</style>
