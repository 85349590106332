<template>
  <div>
    <div v-if="!editName" class="left-panel__campaign">
      <div
        class="shorten-text"
        :class="isActive ? 'left-panel__title' : 'left-panel__title-disabled'"
      >
        {{ campaign.name }}
      </div>
      <hcc-button-icon
        v-if="!isOwner"
        v-tooltip="editCampaignName"
        @click="openEditName"
        size="sm"
        class="edit-icon"
      >
        <pencil-icon />
      </hcc-button-icon>
    </div>
    <div v-else class="left-panel__edit">
      <hcc-input
        v-focus
        class="left-panel__input"
        v-model.trim="inputName"
        @keyup.enter="saveEditName"
      />
      <div class="left-panel__icons">
        <hcc-button-icon
          color="alert-green"
          :muted="false"
          v-tooltip="accept"
          @click="saveEditName"
        >
          <check-icon />
        </hcc-button-icon>
        <hcc-button-icon
          color="alert"
          :muted="false"
          v-tooltip="cancel"
          @click="cancelEditName"
        >
          <close-icon />
        </hcc-button-icon>
      </div>
    </div>
    <div class="left-panel__data">
      <div class="left-panel__data-bold">
        <span class="left-panel__subtitle">{{ status }}:</span>
        <span
          :class="
            isActive ? 'left-panel__data-active' : 'left-panel__data-inactive'
          "
          >{{ statusLabel }}</span
        >
      </div>
      <div class="data">
        <span class="left-panel__subtitle">{{ admins }}:</span>
        <span>{{ campaign.admins }}</span>
      </div>
      <div class="data">
        <span class="left-panel__subtitle">{{ agents }}:</span>
        <span>{{ campaign.agents }}</span>
      </div>
      <div class="data">
        <span class="left-panel__subtitle">{{ channelsLabel }}:</span>
        <span v-if="campaign.channels.length === 0">0</span>
        <channel-badge
          v-else
          v-for="(channel, index) in channelTypes"
          :key="index"
          :type="channel"
          class="left-panel__badge"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HccInput from '@/components/shared/HccInput/index.vue';
import HccButtonIcon from '@/components/shared/HccButtonIcon/index.vue';
import ChannelBadge from '@/components/ChannelBadge.vue';

export default {
  components: {
    HccInput,
    HccButtonIcon,
    ChannelBadge,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    isOwner: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      editName: false,
      inputName: '',
    };
  },
  directives: {
    focus: {
      inserted(el) {
        el.children[0].children[0].focus();
      },
    },
  },
  computed: {
    isActive() {
      return this.campaign.active;
    },
    editCampaignName() {
      return this.$t('campaigns.cards.edit-campaign-name');
    },
    accept() {
      return this.$t('campaigns.cards.accept');
    },
    cancel() {
      return this.$t('campaigns.cards.cancel');
    },
    status() {
      return this.$t('campaigns.cards.status');
    },
    statusLabel() {
      return this.isActive ? this.$t('campaigns.cards.active') : this.$t('campaigns.cards.inactive');
    },
    admins() {
      return this.$t('campaigns.cards.admins');
    },
    agents() {
      return this.$t('campaigns.cards.agents');
    },
    channels() {
      return this.campaign.channels;
    },
    channelTypes() {
      return this.campaign.channels.map(c => c.channelType.key);
    },
    channelsLabel() {
      return this.$t('campaigns.cards.channels');
    },
  },
  methods: {
    openEditName() {
      this.editName = true;
      this.inputName = this.campaign.name;
    },
    cancelEditName() {
      this.editName = false;
      this.inputName = this.campaign.name;
    },
    saveEditName() {
      if (this.inputName.length === 0 || this.inputName === this.campaign.name) {
        this.cancelEditName();
      } else {
        const data = {
          id: this.campaign.id,
          name: this.inputName,
        };

        this.$emit('updated', data);
        this.editName = false;
      }
    },
  },

};
</script>

<style lang="scss" scoped>
@import "~styles/components/_campaign-card.scss";
</style>
